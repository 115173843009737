import styled from "styled-components";
import { Container } from "../container";
import { theme } from "../../utils";

export const StickyFooter = styled(Container)`
  position: sticky;
  bottom: 0px;
  background: ${theme.colors.bg.alt};
  // margin: 0px -24px;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
`;