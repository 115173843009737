import styled, { css } from "styled-components";

export const SvgBase = styled.svg`
  fill: ${({ color }) => color || '#fff'};
  width: 16px;
  height: 16px;
  
  ${({ size }) =>
    size && css`
      width: ${size * 8}px;
      height: ${size * 8}px;
    `
  }
`;