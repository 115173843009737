import React from 'react'
import useSlotMetadata from './use-slot-metadata';
import SlotGenerator from './slot-generator';
import useFilters from './use-filters';
import { slotFilters } from './filters';

export default function Page() {
  const { metadata } = useSlotMetadata();
  const {
    filters,
    selectedFilters,
    updateSelectedFilters,
    appliedFilters,
    applyFilters,
    resetFilters,
    clearSelectedFilters,
  } = useFilters(metadata?.fieldValues, slotFilters);

  if (!metadata || !filters) {
    return null;
  }

  return (
    <div style={{ height: "100%"}}>
      <SlotGenerator
        filters={filters}
        selectedFilters={selectedFilters}
        updateSelectedFilters={updateSelectedFilters}
        appliedFilters={appliedFilters}
        applyFilters={applyFilters}
        metadata={metadata}
        resetFilters={resetFilters}
        clearSelectedFilters={clearSelectedFilters}
      />
    </div>
  )
}
