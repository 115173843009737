import React from 'react'
import Details from './details'
import styled from 'styled-components'
import { theme } from '../../utils';

const Wrapper = styled.div`
  ${theme.screens.gt_md} {
    display: none;
  }
`;

export default function MobileSlotCard({ slot }) {
  return (
    <Wrapper>
      <img src={`https://images.casinostrats.com/images/${slot.image}`} className='img' />
      <div style={{ padding: "24px" }}>
        <Details slot={slot} />
      </div>
    </Wrapper>
  )
}
