import React from 'react'
import { StyledButton } from "./styles";

export default function Button({ icon, children, ...props }) {
  return (
    <StyledButton icon={icon} {...props}>
      {icon}
      {children}
    </StyledButton>
  )
}
