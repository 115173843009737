
import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { theme } from '../../utils';
import { Distance, FlexContainer, FlexItem, H3, SpaceBetween, TextBase, TextTwo } from '../../components-stateless';
import { CloseIcon } from '../../utils/icons';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const collapseHeight = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const BottomSheetContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 85%; /* Adjust the value as needed */
  z-index: 100;
  background-color: ${theme.colors.bg.main};
  border-radius: 12px 12px 0px 0px;
  animation: ${({ isOpen }) => isOpen ? slideUp : slideDown} 0.3s ease-in-out forwards;

  ${theme.screens.gt_md} {
    width: 480px;
    height: 100%;
    border-radius: 0px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.8);
  animation: ${({ isOpen }) =>
    isOpen
      ? css`${fadeIn} 0.3s ease-in-out forwards`
      : css`${fadeOut} 0.3s ease-in-out forwards, ${collapseHeight} 0.3s 0.3s forwards`};
`;

const PanelBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;


const BottomSheet = ({
  isOpen,
  onClose,
  children,
  footer,
  title,
  caption,
}) => {
  const bodyRef = useRef(null);

  useEffect(() => {
    if (bodyRef?.current && isOpen) {
      disableBodyScroll(bodyRef.current);
    }

    if(!isOpen) {
      clearAllBodyScrollLocks();
    }
    
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  // the inital value for isOpen should be null if 
  // useBottomSheet hook is used to toggle isOpen. This 
  // is needed to prevent animations from firing on mount.
  if (isOpen === null) {
    return null;
  }

  return (
    <div>
      <Backdrop isOpen={isOpen} onClick={onClose} />
      <BottomSheetContainer isOpen={isOpen} >
        <FlexContainer height="100%" flexDirection="column">
          <FlexItem style={{ padding: "24px" }}>
            <SpaceBetween>
              <FlexItem>
                {title && (
                  <H3>{title}</H3>
                )}
                {caption && (
                  <TextTwo>{caption}</TextTwo>
                )}
              </FlexItem>
              {!!onClose && (
                <FlexItem onClick={onClose}>
                  <CloseIcon />
                </FlexItem>
              )}
            </SpaceBetween>
          </FlexItem>
          <PanelBody ref={bodyRef}>
            {children}
          </PanelBody>
          <FlexItem style={{ padding: "24px" }}>
            {footer}
          </FlexItem>
        </FlexContainer>
      </BottomSheetContainer>
    </div>
  );
};

export default BottomSheet;