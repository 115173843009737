export const isStrictlyObject = (property) =>
    typeof property === 'object' &&
    !Array.isArray(property) &&
    property !== null

export const objectToArrayOfObjects = (object) =>
    Object.entries(object).map((e) => ({ property: e[0], value: e[1] }));

export const isEmpty = (object) => Object.keys(object)?.length < 1;

export const numOfKeys = (object) => Object.keys(object).length;

export const sortAlphabetically = (object) =>
    Object.keys(object).sort().reduce((obj, key) => {
        obj[key] = object[key];
        return obj;
    }, {}); 