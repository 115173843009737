import { useCallback, useEffect, useState } from "react";
import { sortAlphabetically } from "../../utils/helpers";

export default function useFilters(fieldValues, availableFilters) {
  const [filters, setFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  useEffect(() => {
    if (fieldValues) {
      const mappedFilters = availableFilters.map((af) => {
        const matchingFieldValues = fieldValues[af.property] || {};
        const sortedValues = sortAlphabetically({...matchingFieldValues});

        return ({
          ...af,
          values: sortedValues,
        })
      });

      setFilters(mappedFilters);
    }
  }, [fieldValues, availableFilters]);


  const updateSelectedFilters = useCallback((property, values) => {
    setSelectedFilters((prev) => {
      if(values === null) {
        let next = {...prev};
        delete next[property];
        return next;
      } else {
        return {...prev, [property]: values};
      }
    })
  }, []);

  const applyFilters = () => {
    setAppliedFilters(selectedFilters);
  }

  const clearSelectedFilters = () => {
    setSelectedFilters(appliedFilters);
  }

  const resetFilters = () => {
    setAppliedFilters({});
    setSelectedFilters({});
  }

  return { 
    filters,
     updateSelectedFilters, 
     selectedFilters,
     appliedFilters,
     applyFilters,
     resetFilters,
     clearSelectedFilters,
     };
}