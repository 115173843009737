import React from 'react'
import { SvgBase } from './styled-components'

export default function Icon({ size, color, children }) {
  return (
    <SvgBase
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 24 24`}
      size={size}
      color={color}
    >
      {children}
    </SvgBase>
  )
}
