import React from 'react'
import { BadgeContainer, StyledBadge } from './styles'

export default function Badge({ children, value }) {
  return (
    <BadgeContainer>
      {children}
      {value > 0 && (
        <StyledBadge>
          {value}
        </StyledBadge>
      )}
    </BadgeContainer>
  )
}
