import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid ${props => (props.checked ? '#4b9667' : '#ced4da')};
  background-color: ${props => (props.checked ? '#4b9667' : 'transparent')};
  border-radius: 4px;
  transition: background-color 0.25s, border-color 0.25s;

  &:after {
    content: '\u2713';
    font-size: 14px;
    color: white;
    display: ${props => (props.checked ? 'block' : 'none')};
    text-align: center;
    line-height: 1;
  }
`;


const Checkbox = ({ className, checked, onChange, id }) => {
  return (
    <CheckboxContainer className={className}>
      <StyledCheckbox checked={checked} />
    </CheckboxContainer>
  );
}

export default Checkbox;

