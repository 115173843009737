import React from "react";
import { HeroWrapper, HeroLead } from "./index";
import { Content, H1 } from "../index";

export default function Hero({ title, lead, children }) {
  return (
    <>
      <HeroWrapper>
        <H1>{title}</H1>
        <HeroLead>
          {lead}
        </HeroLead>
        {children}
      </HeroWrapper>
    </>
  );
}
