import React from 'react'
import { Distance, H2 } from '../../components-stateless'
import { theme } from '../../utils'

export default function Details({ slot }) {
  return (
    <div>
      <H2>{slot.title}</H2>
      <Distance />
      <div className="details">
        <div className="detail">
          <span className="label">Provider:</span>
          <span className="value">{slot.provider ?? '-'}</span>
        </div>

        <div className="detail">
          <span className="label">RTP:</span>
          <span className="value">{slot.rtp ?? '-'}</span>
        </div>

        <div className="detail">
          <span className="label">Max Win:</span>
          <span className="value">{slot.maxWin ?? '-'}</span>
        </div>

        <div className="detail">
          <span className="label">Volatility:</span>
          <span className="value">{slot.volatility ?? '-'}</span>
        </div>

        <div className="detail">
          <span className="label">Min Bet:</span>
          <span className="value">{slot.minBet ?? '-'}</span>
        </div>

        <div className="detail">
          <span className="label">Max Bet:</span>
          <span className="value">{slot.maxBet ?? '-'}</span>
        </div>

        <div className="detail">
          <span className="label">Release Date:</span>
          <span className="value">{slot.releaseDate ?? '-'}</span>
        </div>

      </div>
      <style jsx>{`
        .img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          display: block;
          background: ${theme.colors.bg.alt}
        }

        .details {
          border-top: 1px solid #666;
          padding-top: 16px;
        }

        .detail {
          display: flex;
          justify-content: space-between;
        //  gap: 8px;
          margin-bottom: 8px;
        }

        .label {
          font-weight: bold;
          color: #fff;
        }
        
        .value {
          color: #b2becd;
        }
      `}</style>
    </div>
  )
}
