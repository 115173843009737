import React from 'react'
import styled from 'styled-components'
import { Content, Distance, FlexContainer, FlexItem, H2 } from '../../components-stateless';
import { theme } from '../../utils';
import Details from './details';
import { getStorage, ref } from "firebase/storage";
import { _get } from '../../api/request';

const Wrapper= styled(FlexContainer)`
    display: none;

    ${theme.screens.gt_md} {
        display: flex;
    }
`;

const Image = styled.img`
    height: 375px;
    width: 500px;
    object-fit: cover;
    display: block;
    background: ${theme.colors.bg.alt};

    ${theme.screens.lt_lg} {
        height: auto;
        width: 400px;
    }
`;

const Detail = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; 
`;

export default function DesktopSlotCard({ slot }) {

    return (
        <Wrapper alignItems="center" >
            <FlexItem>
                <Image src={`https://images.casinostrats.com/images/${slot.image}`} />
            </FlexItem>
            <FlexItem flexGrow={1}>
                <div style={{ padding: "24px", width: "100%" }}>
                    <H2>{slot.title}</H2>
                    <Distance />
                    <div style={{ borderTop: "1px solid #666" }}></div>
                    <Distance />
                    <div>
                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>Provider:</span>
                            <span style={{ color: '#b2becd' }}>{slot.provider ?? '-'}</span>
                        </Detail>

                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>RTP:</span>
                            <span style={{ color: '#b2becd' }}>{slot.rtp ?? '-'}</span>
                        </Detail>

                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>Max Win:</span>
                            <span style={{ color: '#b2becd' }}>{slot.maxWin ?? '-'}</span>
                        </Detail>

                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>Volatility:</span>
                            <span style={{ color: '#b2becd' }}>{slot.volatility ?? '-'}</span>
                        </Detail>

                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>Min Bet:</span>
                            <span style={{ color: '#b2becd' }}>{slot.minBet ?? '-'}</span>
                        </Detail>

                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>Max Bet:</span>
                            <span style={{ color: '#b2becd' }}>{slot.maxBet ?? '-'}</span>
                        </Detail>

                        <Detail>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>Release Date:</span>
                            <span style={{ color: '#b2becd' }}>{slot.releaseDate ?? '-'}</span>
                        </Detail>

                    </div>
                </div>
            </FlexItem>
        </Wrapper>
    )
}
