import styled from "styled-components";

export const BadgeContainer = styled.div`
    position: relative;
    display: flex;
`;

export const StyledBadge = styled.span`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(50%, -50%);
    height: 16px;
    min-width: 16px;
    background-color: #4b9667;
    border-radius: 10px;
    font-size: 10px;
    top: 0;
    right: 0;
`;