import React, { useMemo } from 'react'
import { BottomSheet, Panel } from '../../components';
import { Button, FlexContainer, SpaceBetween } from '../../components-stateless';
import { Accordion } from '../../components/accordion';
import Filter from './filter';
import { getFilteredSlotIndexes } from './hooks/functions';

const SlotFilterSheetFooter = ({
  applyFilters,
  setShowFilterPanel,
  resetFilters,
  totalHits
}) => {

  const handleConfirm = () => {
    applyFilters();
    setShowFilterPanel(false);
  }

  const handleClearAll = () => {
    resetFilters();
  }

  return (
    <SpaceBetween gap={8}>
      <Button
        onClick={handleConfirm}
        fullWidth
        maxWidth="200px"
        disabled={totalHits === 0}
      >
        Apply filters
      </Button>
      <Button
        onClick={handleClearAll}
        fullWidth
        maxWidth="200px"
        secondary
      >
        Clear all
      </Button>
    </SpaceBetween>
  );
}

export default function SlotFilterSheet({
  setShowFilterPanel,
  applyFilters,
  filters,
  selectedFilters,
  updateSelectedFilters,
  resetFilters,
  metadata,
  clearSelectedFilters,
}) {

  const onClose = () => {
    setShowFilterPanel()
    clearSelectedFilters();
  }

  const totalHits = useMemo(() => {
    const filteredSlotIndexes = getFilteredSlotIndexes(selectedFilters);

    return filteredSlotIndexes !== null ? filteredSlotIndexes.length : metadata.totalDocCount;
  }, [selectedFilters, metadata]);


  return (
    <Panel
      onClose={onClose}
      footer={(
        <SlotFilterSheetFooter
          applyFilters={applyFilters}
          setShowFilterPanel={setShowFilterPanel}
          resetFilters={resetFilters}
          totalHits={totalHits}
        />
      )}
      title={'Filters'}
      caption={`Total hits: ${totalHits}`}
    >
      {filters && filters.map((filter, index) => (
        <Accordion
          title={filter.label}
          key={filter.property}
          lastItem={index === filters.length - 1}
        >
          <FlexContainer
            flexDirection="column"
            gap={24}
            style={{ marginTop: "24px" }}
          >
            <Filter
              filter={filter}
              updateSelectedFilters={updateSelectedFilters}
              selectedFilters={selectedFilters}
            />
          </FlexContainer>
        </Accordion>
      ))}
    </Panel>
  )
}
