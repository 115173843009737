import React from 'react'
import { Button, Card, Content, Distance, FlexContainer, Hero, Section, TextBase } from '../../components-stateless'
import image from "./a14e4b0d57e44db1c4463fc469dcae33.jpg";
import data from "../../temp-db.json";
import { useNavigate } from 'react-router';

const heroTitle = "Random slot generator";
const heroLead = `Need some help with knowing what online slot to play next? Click the button below to go to our tool that will do just that.`;

export default function HomePage() {

const slot = data[0];
const navigate = useNavigate();

    return (
        <>
            <Section fullHeight>
                <FlexContainer justifyContent='center' alignItems='center' height='100%'>
                    <Hero title={heroTitle} lead={heroLead}>
                        <Distance size={24} />
                        <Button onClick={() => navigate("slot-generator")}>Lets go</Button>
                    </Hero>
                </FlexContainer>
            </Section>
        </>
    )
}
