import { collection, getDocs, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../../contexts";
import useCtx from "../../../hooks/use-context";
import { getRandomNumber } from "../../../utils/helpers";

const getSlotIndexWithGuard = (filteredSlotIndexes, diverseRandomGuard) => {
  let randomIndex = getRandomNumber(0, filteredSlotIndexes.length - 1);

  while (diverseRandomGuard.includes(filteredSlotIndexes[randomIndex])) {
    randomIndex = getRandomNumber(0, filteredSlotIndexes.length - 1)
  }

  return filteredSlotIndexes[randomIndex];
};

const getSlotIndexWithoutGuard = (filteredSlotIndexes, currentSlot) => {
  if (!currentSlot) return filteredSlotIndexes[0];

  const oppositeSlot = filteredSlotIndexes.find((i) => i !== currentSlot.index);
  return oppositeSlot;
};

// TODO: Gör om detta, behöver inte vara en hook för den gör inget med state cycles. Kan vara en vanlig funktion eller något?
// Se över om vi skulle null checka filteredSlotIndexes.
export default function useGetFilteredRandomSlot(
  filteredSlotIndexes,
  diverseRandomGuard,
  slot,
  setSlot,
  guardSize,
  updateDiverseRandomGuard,
  setDiverseRandomGuard,
  setLoadingSlot
) {

  const { db } = useCtx(FirebaseContext);
  const slotsCollectionRef = collection(db, 'slots');

  async function fetchFilterededRandomSlot() {
    try {
      setLoadingSlot(true);
      // TODO: hantera ifall man skulle lyckas applya filters så att filtered slot indexes
      // blir tomt.
      let slotIndex = 0;

      if (diverseRandomGuard) {
        slotIndex = getSlotIndexWithGuard(filteredSlotIndexes, diverseRandomGuard);
      } else if (!diverseRandomGuard && filteredSlotIndexes.length === 2) {
        slotIndex = getSlotIndexWithoutGuard(filteredSlotIndexes, slot);
      } else {
        slotIndex = filteredSlotIndexes[0];
      }

      console.log(slotIndex, diverseRandomGuard);

      let q = query(slotsCollectionRef, where('index', '==', slotIndex));

      const data = await getDocs(q);
      setLoadingSlot(false);
      setSlot(data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]);
      
      if (diverseRandomGuard) {
        // TODO: maybe rename this to something like push new index to guard? since we already
        // update the guard when applying filters. This is more to add new slot index to the guard.
        updateDiverseRandomGuard(slotIndex, diverseRandomGuard, guardSize, setDiverseRandomGuard);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return {
    fetchFilterededRandomSlot
  }
}