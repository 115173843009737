import { isEmpty } from "../../../utils/helpers";

export const getFilteredSlotIndexes = (filters) => {
    if (isEmpty(filters)) return null;

    // Represents indexes that were found in all the selected filter values.
    let matchedSlotIndexes = [];

    for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
            const sectionNumbers = new Set(filters[key].flatMap(entry => entry.value));

            if (matchedSlotIndexes.length === 0) {
                matchedSlotIndexes = [...sectionNumbers];
            } else {
                matchedSlotIndexes = matchedSlotIndexes.filter(num => sectionNumbers.has(num));
            }
        }
    }

    return matchedSlotIndexes;
}

const STORAGE_KEY = 'lastFetchedGameIndexes';

export const syncInitGuardStorage = (savedIndexes, size) => {
    try {
        const parsedIndexes = JSON.parse(savedIndexes);

        if (Array.isArray(parsedIndexes) && parsedIndexes.every((index) => Number.isInteger(index))) {
            // maxAmount is the total amount of stored indexes we want. We subtract with 
            // one too leave room for the next generated index. Size should never be less than
            // two, if it is you should simply not use randomized generator.
            const maxAmount = size - 1;
            // Uses negative number here since we want to grab form the end of the array.
            return parsedIndexes.slice(-maxAmount);
        }
    } catch (error) {
        console.error('Error parsing saved indexes:', error);
        return [];
    }
}

export const getNewGuard = (currentGuard, size) => {
    const guard = !currentGuard ? [] : [...currentGuard]; 

    const maxAmount = size - 1;
    const newGuard = guard.slice(-maxAmount);

    return newGuard;
}

export const getInitialGuard = (size) => {
    if (size < 2) return null;
    const savedIndexes = sessionStorage.getItem(STORAGE_KEY);
    if (savedIndexes) {
        return syncInitGuardStorage(savedIndexes, size);
    } else {
        return [];
    }
}

export const updateDiverseRandomGuard = (index, currentGuard, size, dispatch) => {
    const newGuard = getNewGuard(currentGuard, size);
    newGuard.push(index);

    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newGuard));
    dispatch(newGuard);
};
  