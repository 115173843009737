import React from 'react'
import { Checkbox, FlexContainer, TextBase } from '../../components-stateless';

export default function Filter({ filter, updateSelectedFilters, selectedFilters }) {
  const isChecked = (filterValue) => !!selectedFilters[filter.property] &&
    !!selectedFilters[filter.property].find(({ key }) => key === filterValue);

  const handleSelectFilter = (filterKey, filterValue) => {
    if (!selectedFilters[filter.property]) {
      updateSelectedFilters(filter.property, [{ key: filterKey, value: filterValue }]);
    } else {
      const newSelectedValues = isChecked(filterKey) ?
        selectedFilters[filter.property].filter((v) => v.key !== filterKey)
        : [...selectedFilters[filter.property], { key: filterKey, value: filterValue }];

      updateSelectedFilters(filter.property, newSelectedValues.length > 0 ? newSelectedValues : null);
    }

  }

  return (
    <>
      {Object.keys(filter.values).map((filterValue) => (
          <FlexContainer
            key={filterValue}
            alignItems="center"
            gap={8}
            onClick={() => handleSelectFilter(filterValue, filter.values[filterValue])}
            style={{ cursor: "pointer" }}
          >
            <Checkbox checked={isChecked(filterValue)} id={filterValue}/>
            <TextBase disableTopGutter>{filterValue}</TextBase>
          </FlexContainer>
      ))}
    </>
  )
}
