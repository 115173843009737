
import React, { useEffect, useRef, useState } from 'react';
import { FlexContainer, FlexItem, H3, SpaceBetween, TextTwo } from '../../components-stateless';
import { CloseIcon } from '../../utils/icons';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { Backdrop, PanelBody, PanelContainer } from './styles';


const Panel = ({
  onClose,
  children,
  footer,
  title,
  caption,
}) => {
  const bodyRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (bodyRef?.current) {
      disableBodyScroll(bodyRef.current);
    }
    
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); 
  }


  return (
    <div>
      <Backdrop onClick={handleClose} isClosing={isClosing}/>
      <PanelContainer isClosing={isClosing} >
        <FlexContainer height="100%" flexDirection="column">
          <FlexItem style={{ padding: "24px" }}>
            <SpaceBetween>
              <FlexItem>
                {title && (
                  <H3>{title}</H3>
                )}
                {caption && (
                  <TextTwo>{caption}</TextTwo>
                )}
              </FlexItem>
                <FlexItem onClick={handleClose}>
                  <CloseIcon />
                </FlexItem>
            </SpaceBetween>
          </FlexItem>
          <PanelBody ref={bodyRef}>
            {children}
          </PanelBody>
          <FlexItem style={{ padding: "24px" }}>
            {footer}
          </FlexItem>
        </FlexContainer>
      </PanelContainer>
    </div>
  );
};

export default Panel;