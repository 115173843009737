import React, { useState } from 'react'
import useSlotGenerator from './hooks/use-slot-generator';
import { Button, Card, Container, Content, FlexContainer, FlexItem, Section, SpaceBetween, Spinner, StickyFooter, TextTwo } from '../../components-stateless';
import Details from './details';
import { FilterIcon } from '../../utils/icons';
import { useBottomSheet } from '../../components';

import { isEmpty, numOfKeys } from '../../utils/helpers';
import SlotFilterSheet from './slot-filter-sheet';
import Badge from '../../components-stateless/badge/badge';
import DesktopSlotCard from './desktop-slot-card';
import MobileSlotCard from './mobile-slot-card';

export default function SlotGenerator({
  filters,
  selectedFilters,
  updateSelectedFilters,
  metadata,
  appliedFilters,
  applyFilters,
  resetFilters,
  clearSelectedFilters,
}) {
  const {
    slot,
    fetchRandomSlot,
    fetchFilterededRandomSlot,
    filteredSlotIndexes,
    loadingSlot
  } = useSlotGenerator(appliedFilters, metadata);
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  return (
    <>
      <FlexContainer flexDirection="column" height="100%">
        <Section fullHeight >
          {slot && !loadingSlot && (
            <FlexContainer height="100%" flexDirection="column" justifyContent="center">
              <Container py="32px">
                <Card rounded>
                  <DesktopSlotCard slot={slot} />
                  <MobileSlotCard slot={slot} />
                </Card>
              </Container>
            </FlexContainer>

          )}
          {loadingSlot && (
            <FlexContainer justifyContent='center' alignItems='center' height='100%' >
              <Spinner size="48px" />
            </FlexContainer>
          )}
        </Section>

        <StickyFooter py="20px">
          <Content>
            <SpaceBetween gap={16}>
              <Button
                onClick={() =>
                  isEmpty(appliedFilters) ? fetchRandomSlot() : fetchFilterededRandomSlot()
                }
                fullWidth
                maxWidth="200px"
                disabled={
                  filteredSlotIndexes &&
                  filteredSlotIndexes.length === 1 &&
                  filteredSlotIndexes[0] === slot.index
                }
              >
                New game
              </Button>
              <Button
                icon={(
                  <Badge value={numOfKeys(appliedFilters)}>
                    <FilterIcon />
                  </Badge>
                )}
                secondary
                onClick={() => setShowFilterPanel(true)}
                fullWidth
                maxWidth="200px"
              >
                Filters
              </Button>
            </SpaceBetween>
          </Content>
        </StickyFooter>
      </FlexContainer>

      {showFilterPanel && (
        <SlotFilterSheet
          setShowFilterPanel={setShowFilterPanel}
          applyFilters={applyFilters}
          filters={filters}
          selectedFilters={selectedFilters}
          updateSelectedFilters={updateSelectedFilters}
          resetFilters={resetFilters}
          metadata={metadata}
          clearSelectedFilters={clearSelectedFilters}
        />
      )}
    </>
  )
}
