
export const slotFilters = [
    {
        property: 'volatility',
        label: 'Volatility',
        type: 'multiSelect',
        operator: 'in'
    },
    {
        property: 'provider',
        label: 'Provider',
        type: 'multiSelect',
        operator: 'in'
    }
]