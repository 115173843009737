import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export default createGlobalStyle`
  :root {
    color-scheme: dark;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: ${theme.font.family.main};
    line-height: ${theme.font.lineHeight};
    letter-spacing: ${theme.font.letterSpacing};
    background: linear-gradient(176deg, rgb(18, 24, 27) 50%, rgb(32, 39, 55) 100%);
    background-attachment: fixed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root, body {
    min-height: 100vh;
  }

  /* Apply custom scrollbar styles only above 900px */
  @media screen and (min-width: 900px) {
    /* WebKit Scrollbars (Safari and some Chrome versions) */
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* Adjust the size of the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
      background: #666;
      border-radius: 5px;
    }

    /* Make the track wider than the thumb and center the thumb */
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;
