import styled, { css, keyframes } from "styled-components";
import { theme } from "../../utils";

export const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

export const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const collapseHeight = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const PanelContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 85%; /* Adjust the value as needed */
  z-index: 100;
  background-color: ${theme.colors.bg.main};
  border-radius: 12px 12px 0px 0px;
  animation: ${slideUp} 0.3s ease-in-out forwards;

  ${({ isClosing }) => isClosing && css`
    animation: ${slideDown} 0.3s ease-in-out forwards;
  `}

  ${theme.screens.gt_md} {
    width: 480px;
    height: 100%;
    border-radius: 0px;
    animation:  ${slideIn} 0.3s ease-in-out forwards;

    ${({ isClosing }) => isClosing && css`
    animation: ${slideOut} 0.3s ease-in-out forwards;
  `}
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 0.3s ease-in-out forwards;

  ${({ isClosing }) => isClosing && css`
    animation: ${fadeOut} 0.3s ease-in-out forwards, ${collapseHeight} 0.3s 0.3s forwards;
  `}
`;

export const PanelBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;