import React, { useMemo } from "react";
import { FirebaseContext } from "./index";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBDdrQVxYWztaYU5H-SQDg7d3APPQ5jyyg",
  authDomain: "casino-strats.firebaseapp.com",
  projectId: "casino-strats",
  storageBucket: "casino-strats.appspot.com",
  messagingSenderId: "618033518960",
  appId: "1:618033518960:web:2809fc8ff93731230717b5",
  measurementId: "G-91HHMV7RRH"
};

export default function FirebaseProvider({ children }) {
  const app = initializeApp(firebaseConfig);

  // might use this to log events later
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  const value = useMemo(
    () => ({
      analytics,
      db,
    }),
    [
      analytics,
      db,
    ]
  );

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
}