import styled, { css } from "styled-components";
import { theme } from "../../utils";

export const StyledButton = styled.button`
  background: ${({ bg }) => bg || theme.colors.button.bg.main};
  color: ${({ color }) => color || theme.colors.button.text.main};
  font-weight: ${theme.font.weight.regular};
  font-family: ${theme.font.family.main};
  font-size: 16px;
  appearance: none;
  border: none;
  padding: 0px 24px;
  border-radius: 25px;
  height: 40px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :active {
    transform: scale(0.97);
  }

  ${({ secondary }) =>
    secondary && css`
      // background: ${theme.colors.button.bg.alt};
      // color: #fff;
      border: 1px solid #666;
      background: none;
    `
  };

  ${({ large }) =>
    large && css`
      height: 48px;
    `
  };

  ${({ icon }) =>
    icon && css`
      display: flex;
      gap: 16px;
    `
  };

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `};

  ${({ outlined }) =>
    outlined &&
    css`
      background: none;
      border: 1px solid ${outlined};
    `};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;
