import { useEffect, useState } from "react"
import useCtx from "../../hooks/use-context";
import { FirebaseContext } from "../../contexts";
import { doc, getDoc } from "firebase/firestore";

export default function useSlotMetadata() {
  const [metadata, setMetadata] = useState(null);
  const { db } = useCtx(FirebaseContext);

  const metadataDocRef = doc(db, "slots-metadata", "Rfa5JLbv1Ax7oLhO44p8");

  const initializeMetadata = async () => {
    const metadataDoc = await getDoc(metadataDocRef);
    const metadataDocValues = metadataDoc.data();
    setMetadata(metadataDocValues);
  }

  useEffect(() => {
    initializeMetadata();
  }, [])

  return {
    metadata
  }
}