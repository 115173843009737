import React, { useState } from 'react';
import { FlexContainer, TextBase } from '../../components-stateless';
import { DownIcon, UpIcon } from '../../utils/icons';
import { theme } from '../../utils';

const Accordion = ({ title, children, lastItem }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ borderBottom: lastItem ? 'none': "1px solid #666", margin: "0px 24px", padding: "24px 0px" }}>
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        onClick={() => toggleAccordion()}
        style={{ cursor: "pointer"}}
      >
        <TextBase
          disableTopGutter
          style={{ fontWeight: theme.font.weight.medium }}
        >
          {title}
        </TextBase>
        {!expanded && <DownIcon />}
        {expanded && <UpIcon />}
      </FlexContainer>
      {expanded && children}
    </div>
  );
};

export default Accordion;
