import styled from 'styled-components';
import { theme } from '../../utils';

export const TextTwo = styled.div`
  margin: 0px;
  font-size: ${({ smallerText }) => (smallerText ? '14px' : '16px')};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.colors.text.alt};

  ${theme.screens.gt_md} {
    font-size: ${({ smallerText }) => (smallerText ? '16px' : '18px')};
  }
`;
