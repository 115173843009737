import styled, { css } from "styled-components";
import { theme } from "../../utils";


export const Card = styled.div`
  background: ${({ bg }) => bg || theme.colors.bg.main};
  border-radius: ${({ rounded }) => rounded && "12px"};
  // box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;

    ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}

    ${({ fullHeight }) =>
    fullHeight && css`
        height: 100%;
      `
  }
`;
