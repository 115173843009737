import { useEffect, useState } from "react";
import { getTwoThirds, isEmpty } from "../../../utils/helpers";
import useCtx from "../../../hooks/use-context";
import { FirebaseContext } from "../../../contexts";
import { collection, getDocs, query, where } from "firebase/firestore";
import useOnlyOnUpdateEffect from "../../../hooks/use-only-update-effect";
import { getInitialGuard, getNewGuard, updateDiverseRandomGuard } from "./functions";
import useGetFilteredRandomSlot from "./use-get-filtered-random-slot";


const getFilteredSlotIndexes = (appliedFilters) => {
  if (isEmpty(appliedFilters)) return null;

  // Represents indexes that were found in all the selected filter values.
  let matchedSlotIndexes = [];

  for (const key in appliedFilters) {
    if (appliedFilters.hasOwnProperty(key)) {
      const sectionNumbers = new Set(appliedFilters[key].flatMap(entry => entry.value));

      if (matchedSlotIndexes.length === 0) {
        matchedSlotIndexes = [...sectionNumbers];
      } else {
        matchedSlotIndexes = matchedSlotIndexes.filter(num => sectionNumbers.has(num));
      }
    }
  }

  return matchedSlotIndexes;
}

const getThis = (filteredSlotIndexes, metadata) => {
  if (filteredSlotIndexes !== null) {
    // då vill vi kolla storleken på outcome / längd av de indexen som klarade filtreringen
    // är den mer än 3? ta fram en siffra på en tredjedel av det.
    // är det mindre än 3 så vill vi inte använda guard utan förlita oss på att vi vid
    // fetchning eller logiken som bestämmer vad vi ska fetcha inte fetchar den som redan är vald.
    if (filteredSlotIndexes.length >= 3) {
      return Math.round(getTwoThirds(filteredSlotIndexes.length));
    } else {
      return null;
    }

  } else {
    // inga filter betyder att vi bara har totalcount av dokument att förlita oss på
    // vi vill dock även här ta ut en tredjedel av totalen. Samma här kolla om det går att dela på 3
    // om inte så får vi igen lita på att inte fetcha den som redan är vald.

    if (metadata.totalDocCount >= 3) {
      return Math.round(getTwoThirds(metadata.totalDocCount));
    } else {
      return null;
    }
  }

}

const getRandomNumber = (minValue, maxValue) => {
  // if (!minValue || !maxValue) return null;

  let randomNumber = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  return randomNumber;
}

export default function useSlotGenerator(appliedFilters, slotsMetadata) {
  const [slot, setSlot] = useState(null);
  const [loadingSlot, setLoadingSlot] = useState(false);
  const [filteredSlotIndexes, setFilteredSlotIndexes] = useState(() => getFilteredSlotIndexes(appliedFilters));
  const [guardSize, setGuardsize] = useState(() => getThis(filteredSlotIndexes, slotsMetadata));
  const [diverseRandomGuard, setDiverseRandomGuard] = useState(() => getInitialGuard(guardSize));

  const { fetchFilterededRandomSlot } = useGetFilteredRandomSlot(
    filteredSlotIndexes,
    diverseRandomGuard,
    slot,
    setSlot,
    guardSize,
    updateDiverseRandomGuard,
    setDiverseRandomGuard,
    setLoadingSlot
  );

  const { db } = useCtx(FirebaseContext);
  const slotsCollectionRef = collection(db, 'slots');

  async function fetchRandomSlot() {
    try {
      setLoadingSlot(true);
      let slotIndex = getRandomNumber(1, slotsMetadata.totalDocCount);
      while (diverseRandomGuard.includes(slotIndex)) {
        slotIndex = getRandomNumber(1, slotsMetadata.totalDocCount);
      }

      let q = query(slotsCollectionRef, where('index', '==', slotIndex));

      const data = await getDocs(q);
      setLoadingSlot(false);
      setSlot(data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]);

      updateDiverseRandomGuard(slotIndex, diverseRandomGuard, guardSize, setDiverseRandomGuard);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isEmpty(appliedFilters) && diverseRandomGuard && !slot) {
      // fetch a random slot
      fetchRandomSlot();
    }
  }, [diverseRandomGuard]);

  useOnlyOnUpdateEffect(() => {
    // kan hända att current guard blir null om man har fått mindre än 3 hits
    // måste se till att hantera detta.

    const newFilteredSlotIndexes = getFilteredSlotIndexes(appliedFilters);
    const newGuardSize = getThis(newFilteredSlotIndexes, slotsMetadata);
    const newDiverseRandomGuard = newGuardSize ? 
      getNewGuard(diverseRandomGuard, newGuardSize) : null;

    setFilteredSlotIndexes(newFilteredSlotIndexes);
    setGuardsize(newGuardSize);
    setDiverseRandomGuard(newDiverseRandomGuard);
  }, [appliedFilters])

  return {
    slot,
    fetchRandomSlot,
    fetchFilterededRandomSlot,
    filteredSlotIndexes,
    loadingSlot,
  }
}